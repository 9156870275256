import React, { Component, Fragment } from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import { Link } from 'react-router-dom';
// import Footer from "../../FrontEnd/Footer/Footer"
// import Header from "../../FrontEnd/Header/Header"
import Heart from "../../../assets/img/heart.svg"
import CartIcon from "../../../assets/img/cart.svg"
import Clip3 from "../../../assets/img/dashboard/finishing-clip-3.png"
import Clip1 from "../../../assets/img/dashboard/finishing-clip-1.png"
import Clip7 from "../../../assets/img/dashboard/finishing-clip-7.png"
import deleteIcon from "../../../assets/img/delete-icon.svg"
import Checked from "../../../assets/img/checkWhite.svg"
import { confirmAlert } from 'react-confirm-alert';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { cartService } from "../../../services/cart";
import { settingsService } from "../../../services/settings";
import { corporateService } from "../../../services/corporate";
import { forEach } from 'lodash';


class CLMRequestItems extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;


    this.state = {
      cartList: [],
      cartListItems: [],
      salesTaxAmount: 0,
      salesContactAmount: 0,
      requestAmount: false,
      checkRequestStatus : false,
      orderID: id
    }

    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);

  }

  componentDidMount() {
    this.handleCartListing();
    this.handleSalesTaxAmount();
    this.handleSalesContactAmount();
    setTimeout(() => {
      this.handleRequestStats();
    },200);
  }

  handleRequestStats = () => {
    corporateService.checkRequestStatus(this.state.orderID).then((res) => {
      console.log('res', res.data);
      const { data } = res.data;
      if(res.data === 'YES') {
        this.setState({ checkRequestStatus: true });
      }
    })
  }
  handleSalesContactAmount = () => {
    settingsService.getSalesAmount().then((res) => {
      this.setState({ salesContactAmount: res.data && res.data.value });
      // console.log('AAAAAAAAAA=>', res.data && res.data.value);
    })
  }

  handleCartListing = () => {
    const userId = this.state.orderID; // Replace with how you get the user ID
    const page = 1; // Set the desired page number
    const filter = ''; // Define filter criteria, if any
    const sort = ''; // Define sorting options, if any
    const pageSize = 20; // Set the desired page size
    const status = ''; // Replace with the desired status
    const search = null; // Add a search query if applicable

    cartService.paginationDataOrderItem(userId, page, filter, sort, pageSize, status, search)
      .then(res => {
        console.log('Cart listing response:', res);
        let cartListItems = res && res.data && res.data.pagination && res.data.pagination.data;
        let cartItems = cartListItems && cartListItems.length > 0 ? cartListItems[0].order : null;
        console.log('cartListItems', cartListItems);
        console.log('cartItems:', cartItems);
        if (cartListItems) {
              this.setState({
                cartListItems: cartListItems,
                cartList:cartItems
              });
          }
          forEach(cartListItems, (item) => {
            // let a = 0;
            if (((item.pricing && item.pricing.contact_sales === 1) && (item.pricing && item.pricing.contact_amount)) ||
              // ((item && item.subscription_amount) > Number(this.state.salesContactAmount)) ||
              ((cartItems && cartItems.total_amount) > Number(this.state.salesContactAmount))) {
              this.setState({ requestAmount: true });
              // ++a;
              // console.log('requestAmount', a );
            }
          })
        })
      .catch(err => {
        console.error('Error fetching cart listing:', err);
      });
  }



  handlePlanChange = (itemId, pricingId, newAmount, stripe, intervel) => {
    // let guestToken = localStorage.getItem('guest_token');
    const updatedData = {
      // guest_token: guestToken,
      subscription_pricing_id: pricingId, // Replace this with logic to get the appropriate pricing ID
      subscription_stripe_id: stripe,   // Replace this with logic to get the Stripe ID
      subscription_amount: newAmount,
      interval_count: intervel
    };
    // Call the API to update the subscription plan
    cartService.editSubscriptionPlan(updatedData, itemId)
      .then((response) => {
        console.log('Plan updated successfully:', response);
        // Update the UI state (e.g., reload cart items or update the state directly)
        this.handleCartListing();
      })
      .catch((error) => {
        console.error('Error updating plan:', error);
        // Optionally show an error message to the user
      });
  };

  deleteRecord(recID) {
    cartService.deleteOrderItem(recID).then(res => {
      this.handleCartListing();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }

  handleSalesTaxAmount = () => {
    cartService.getSalesTax().then(res => {
      console.log(res);
      this.setState({ salesTaxAmount: res.data && res.data.value });
    })
  }


  render() {
    return (
      <Fragment>
        {/* <Header /> */}
        <main className='content pl-2-5F pt-2'>
          <h2 className='secHeading mt-0'>Your Order Summary</h2>
          <Row>
            <Col xs={12} md={8}>
              <table className='table table-hover table-responsive w-100'>
                <thead class="">
                  <tr>
                    <th width="30%"><strong>Domain</strong></th>
                    <th><strong>Type</strong></th>
                    <th><strong>Year</strong></th>
                    <th><strong>Status</strong></th>
                    <th><strong>Price</strong></th>
                    <th className='text-right'><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.cartListItems && this.state.cartListItems.map((item, index) => {
                    const selectedDuration = item.subscription_amount === item.pricing.one_year_license
                      ? 'one_year'
                      : item.subscription_amount === item.pricing.two_year_license
                        ? 'two_year'
                        : item.subscription_amount === item.pricing.three_year_license
                          ? 'three_year'
                          : '';

                    return (
                      <tr key={item.id}>
                        <td>{item.caret_title || 'Unknown'}</td>
                        <td>{item.pricing && item.pricing.title || 'Unknown Plan'}</td>
                        <td>
                          <select
                            className="rounded w-autoF selectYear"
                            value={selectedDuration}
                            // onChange={(e) => {
                            //   const duration = e.target.value;
                            //   const licenseAmount = item.pricing[`${duration}_license`];
                            //   const stripeId = item.pricing[`stripe_id_${duration}`];
                            //   let interval = 0;
                            //   if (duration === 'one_year') {
                            //     interval = 1;
                            //   } else if (duration === 'two_year') {
                            //     interval = 2;
                            //   } else if (duration === 'three_year') {
                            //     interval = 3;
                            //   }

                            //   this.handlePlanChange(item.id, item.pricing && item.pricing.id, licenseAmount, stripeId, interval);

                            // }}
                            disabled
                          >
                            <option value="one_year">1 Year</option>
                            <option value="two_year">2 Years</option>
                            <option value="three_year">3 Years</option>
                          </select>
                        </td>
                        <td>{item.subscription_status ==='active'?'Active':'Pending'}</td>
                        <td>
                          {/* <strong>${parseFloat(item.subscription_amount).toFixed(2)}</strong> */}
                          {(
                            ((item.pricing && item.pricing.contact_sales === 1) && (item.pricing && item.pricing.contact_amount)) ||
                            ((item && item.subscription_amount) > Number(this.state.salesContactAmount))
                          ) ?
                            (
                              <strong>Contact Carets</strong>

                            ) : (

                              <strong>${parseFloat(item.subscription_amount).toFixed(2)}</strong>
                            )
                          }

                        </td>
                        <td align="right">
                          <a href="#" onClick={() => this.deleteConfirmModal(item.id)}>
                            <img src={deleteIcon} alt="deleteIcon" width={18} />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>

            <Col xs={12} md={4}>
              <div className='altBG py-1 px-2'>
                <h4 className='mt-0'>Order Summary</h4>
                <div className='flexElem alignCenter spaceBetween mb-1'>
                  <p className='m-0'>Total Items: </p>
                  <h4 className='m-0'>{this.state.cartList && this.state.cartList.total_items}</h4>
                </div>

                {!this.state.requestAmount ? (
                  <div>
                    <div className='flexElem alignCenter spaceBetween mb-2'>
                      <h5 className='m-0'>Sub Total:</h5>
                      <h3 className='text-primary m-0'>${this.state.cartList && this.state.cartList.total_amount}</h3>
                    </div>
                    <div className='flexElem alignCenter spaceBetween mb-2'>
                      <h5 className='m-0'>Sales Tax:</h5>
                      <h3 className='text-primary m-0'>${
                        (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                          parseFloat(this.state.salesTaxAmount || 0) / 100)
                      }
                      </h3>
                    </div>
                    <div className='flexElem alignCenter spaceBetween mb-2'>
                      <h5 className='m-0'>Grand Total:</h5>
                      <h3 className='text-primary m-0'>
                        ${
                          (
                            parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) +
                            (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                              parseFloat(this.state.salesTaxAmount || 0) / 100)
                          ).toFixed(2)
                        }
                      </h3>
                    </div>
                  </div>
                 ):(
                  <p className='m-0'>Price Adjust By Admin</p>
                ) }

                  <Link to={this.state.checkRequestStatus ? `/CLM/CLM-request-checkout/${this.state.orderID}` : "#"} className="btn btnDark text-white"
                  disabled = {!this.state.checkRequestStatus}
                  >
                Ready to Purchase
                </Link>

              </div>
            </Col>
          </Row>

        </main>
        {/* <Footer /> */}
      </Fragment >
    );
  }
}

export default CLMRequestItems;
