import React, { Component, Fragment } from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import { Link } from 'react-router-dom';
// import Footer from "../../FrontEnd/Footer/Footer"
// import Header from "../../FrontEnd/Header/Header"
import Heart from "../../../assets/img/heart.svg"
import CartIcon from "../../../assets/img/cart.svg"
import Clip3 from "../../../assets/img/dashboard/finishing-clip-3.png"
import Clip1 from "../../../assets/img/dashboard/finishing-clip-1.png"
import Clip7 from "../../../assets/img/dashboard/finishing-clip-7.png"
import deleteIcon from "../../../assets/img/delete-icon.svg"
import Checked from "../../../assets/img/checkWhite.svg"
import { confirmAlert } from 'react-confirm-alert';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { cartService } from "../../../services/cart";
import { settingsService } from "../../../services/settings";
import { forEach } from 'lodash';
import { helperFunctions as HF } from '../../../helper/helperFunctions';


class CLMCart extends Component {
  constructor(props) {
    super(props);
    // var checkLogin = localStorage.getItem('user');
    // if (checkLogin) {
    //   localStorage.removeItem('user');
    // }

    this.state = {
      cartList: [],
      cartListItems: [],
      salesTaxAmount: 0,
      salesContactAmount: 0,
      meetingTime: '',
      requestAmount: false
    }

    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);

  }

  componentDidMount() {
    this.handleCartListing();
    this.handleSalesTaxAmount();
    this.handleSalesContactAmount();

    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }


//   openCalendlyPopup = (e) => {
//     e.preventDefault();
//     window.Calendly.initPopupWidget({ url: "https://calendly.com/kakar656" });
//   };

openCalendlyPopup = (e) => {
    e.preventDefault();

    // Open Calendly popup
    window.Calendly.initPopupWidget({ url: "https://calendly.com/kakar656" });

    // Listen for the scheduled event
    window.addEventListener("message", async (event) => {
      if (event.data.event === "calendly.event_scheduled") {
        console.log("Calendly Event Data:", event.data);

        const scheduledEvent = event.data.payload;

        if (scheduledEvent?.event?.uri) {
          const eventUri = scheduledEvent.event.uri;

          try {
            // Fetch event details from Calendly API
            const response = await fetch(eventUri, {
              headers: {
                Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzQwNzQ2Mzg2LCJqdGkiOiJmZGE0ZGI3Mi1hMDBiLTRiMzYtYWFjOC03YzY3YWMzZTIzZjAiLCJ1c2VyX3V1aWQiOiJlOWMzMGVjNC0yY2EyLTRhMzgtODJkYS03OTUwOGY0MWNhNTgifQ.085b81RyvoBP5UHEaSK3-M8PcU6d70PVTisPfYMKiQvvk_yMoVGi5dLPKs9howrCDofilJNfW8zmomXNbzaqzA`, // Replace with your API Key
                "Content-Type": "application/json"
              }
            });

            if (!response.ok) {
              throw new Error(`API Error: ${response.statusText}`);
            }

            const eventData = await response.json();
            console.log("Fetched Event Data:", eventData); // Debug log

            // Extract start_time from API response
            const startTime = eventData.resource?.start_time;

            let guestToken = localStorage.getItem('guest_token');

            const updatedData = {
            guest_token: guestToken,
            meeting_time: startTime,
            };

            // Call Laravel API
            cartService.updateCartTime(updatedData)
            .then((response) => {
                console.log('Cart updated successfully:', response);
            })
            .catch((error) => {
                console.error('Error updating Cart:', error);
            });


            this.setState({ meetingTime: startTime });

            // Alert user with the scheduled meeting time
            alert(`Meeting Scheduled on: ${startTime}`);
          } catch (error) {
            console.error("Error fetching event details:", error);
            alert("Error retrieving scheduled event details.");
          }
        } else {
          console.error("Event URI not found:", scheduledEvent);
          alert("Error: Unable to retrieve event details.");
        }
      }
    });
  };






  handleSalesContactAmount = () => {
    settingsService.getSalesAmount().then((res) => {
      this.setState({ salesContactAmount: res.data && res.data.value });
      // console.log('AAAAAAAAAA=>', res.data && res.data.value);
    })
  }

  handleCartListing() {
    let guestToken = localStorage.getItem('guest_token');

    if (guestToken) {
      // If guest_token is available, pass it in the request
      cartService.cartListing(guestToken).then(res => {
        // console.log('cartListing========> ', res && res.cart);
        this.setState({
          cartList: res && res.cart, // The cart details
          cartListItems: res && res.cart && res.cart.items, // The items in the cart
          meetingTime:res.cart.meeting_time
        });
        //this.setState({ meeting_time: true });
        let cartItems = res && res.cart && res.cart.items;
        let cart = res && res.cart;

        forEach(cartItems, (item) => {
          let a = 0;
          if (((item.pricing && item.pricing.contact_sales === 1) && (item.pricing && item.pricing.contact_amount)) ||
            // ((item && item.subscription_amount) > Number(this.state.salesContactAmount)) ||
            ((cart && cart.total_amount) > Number(this.state.salesContactAmount))
          ) {
            this.setState({ requestAmount: true });

            // ++a;
            // console.log('requestAmount', a );
          }
        })

      }).catch(err => {
        console.error('Error fetching cart listing:', err);
      });
    } else {
      console.log('No guest token found in local storage');
    }
  }

  handlePlanChange = (itemId, pricingId, newAmount, stripe, intervel) => {
    // let guestToken = localStorage.getItem('guest_token');
    const updatedData = {
      // guest_token: guestToken,
      subscription_pricing_id: pricingId, // Replace this with logic to get the appropriate pricing ID
      subscription_stripe_id: stripe,   // Replace this with logic to get the Stripe ID
      subscription_amount: newAmount,
      interval_count: intervel
    };
    // Call the API to update the subscription plan
    cartService.editSubscriptionPlan(updatedData, itemId)
      .then((response) => {
        console.log('Plan updated successfully:', response);
        // Update the UI state (e.g., reload cart items or update the state directly)
        this.handleCartListing();
      })
      .catch((error) => {
        console.error('Error updating plan:', error);
        // Optionally show an error message to the user
      });
  };

  deleteRecord(recID) {
    cartService.deleteItem(recID).then(res => {
      this.handleCartListing();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }

  handleSalesTaxAmount = () => {
    cartService.getSalesTax().then(res => {
      console.log(res);
      this.setState({ salesTaxAmount: res.data && res.data.value });
    })
  }


  render() {
    return (
      <Fragment>
        {/* <Header /> */}
        <main className='content pl-2-5F pt-2'>
          <h2 className='secHeading mt-0'>Shopping Cart</h2>
          <Row>
            <Col xs={12} md={8}>
              <table className='table table-hover table-responsive w-100'>
                <thead class="">
                  <tr>
                    <th width="30%"><strong>Domain</strong></th>
                    <th><strong>Type</strong></th>
                    <th><strong>Year</strong></th>
                    <th><strong>Price</strong></th>
                    <th className='text-right'><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.cartListItems && this.state.cartListItems.map((item, index) => {
                    const selectedDuration = item.subscription_amount === item.pricing.one_year_license
                      ? 'one_year'
                      : item.subscription_amount === item.pricing.two_year_license
                        ? 'two_year'
                        : item.subscription_amount === item.pricing.three_year_license
                          ? 'three_year'
                          : '';

                    return (
                      <tr key={item.id}>
                        <td>{item.caret_title || 'Unknown'}</td>
                        <td>{item.pricing && item.pricing.title || 'Unknown Plan'}</td>
                        <td>
                          <select
                            className="rounded w-autoF selectYear"
                            value={selectedDuration}
                            onChange={(e) => {
                              const duration = e.target.value;
                              const licenseAmount = item.pricing[`${duration}_license`];
                              const stripeId = item.pricing[`stripe_id_${duration}`];
                              let interval = 0;
                              if (duration === 'one_year') {
                                interval = 1;
                              } else if (duration === 'two_year') {
                                interval = 2;
                              } else if (duration === 'three_year') {
                                interval = 3;
                              }
                              this.handlePlanChange(item.id, item.pricing && item.pricing.id, licenseAmount, stripeId, interval);

                            }}
                          >
                            <option value="one_year">1 Year</option>
                            <option value="two_year">2 Years</option>
                            <option value="three_year">3 Years</option>
                          </select>
                        </td>

                        <td>
                          {/* <strong>${parseFloat(item.subscription_amount).toFixed(2)}</strong> */}
                          {(
                            ((item.pricing && item.pricing.contact_sales === 1) && (item.pricing && item.pricing.contact_amount)) ||
                            ((item && item.subscription_amount) > Number(this.state.salesContactAmount))
                          ) ?
                            (
                              <strong>Contact Carets</strong>

                            ) : (

                              <strong>${parseFloat(item.subscription_amount).toFixed(2)}</strong>
                            )
                          }
                          {/* {(
                          ((item.pricing && item.pricing.contact_sales === 1) &&  (item.pricing && item.pricing.contact_amount)) ||
                          ((item && item.subscription_amount) > Number(this.state.salesContactAmount))
                          )?
                          (
                            <strong>By Admin</strong>

                          ):(

                            <strong>${parseFloat(item.subscription_amount).toFixed(2)}</strong>
                          )
                        } */}
                        </td>
                        <td align="right">
                          <a href="#" onClick={() => this.deleteConfirmModal(item.id)}>
                            <img src={deleteIcon} alt="deleteIcon" width={18} />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>

            <Col xs={12} md={4}>
              <div className='altBG py-1 px-2'>
                <h4 className='mt-0'>Order Summary</h4>
                <div className='flexElem alignCenter spaceBetween mb-1'>
                  <p className='m-0'>Total Items: </p>
                  <h4 className='m-0'>{this.state.cartList && this.state.cartList.total_items}</h4>
                </div>

                {!this.state.requestAmount ? (
                  <div>
                    <div className='flexElem alignCenter spaceBetween mb-2'>
                      <h5 className='m-0'>Sub Total:</h5>
                      <h3 className='text-primary m-0'>${this.state.cartList && this.state.cartList.total_amount}</h3>
                    </div>
                    <div className='flexElem alignCenter spaceBetween mb-2'>
                      <h5 className='m-0'>Sales Tax:</h5>
                      <h3 className='text-primary m-0'>${
                        (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                          parseFloat(this.state.salesTaxAmount || 0) / 100)
                      }
                      </h3>
                    </div>
                    <div className='flexElem alignCenter spaceBetween mb-2'>
                      <h5 className='m-0'>Grand Total:</h5>
                      <h3 className='text-primary m-0'>
                        ${
                          (
                            parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) +
                            (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                              parseFloat(this.state.salesTaxAmount || 0) / 100)
                          ).toFixed(2)
                        }
                      </h3>
                    </div>

                    <Link to="/CLM/CLM-checkout" className="btn btnDark text-white">
                        Ready to Purchase
                    </Link>

                  </div>
                ) : (
                  <div>

                    <p className='mt-0 text-14 font-weight-bold'>Price Adjust By Admin</p>
                    {this.state.meetingTime ? (
                        <p>Meeting : {HF.dateTimeMy(this.state.meetingTime)}

                            <Link to="/CLM/CLM-checkout" className="btn btnDark text-white">
                                Ready to Purchase
                            </Link>
                        </p>
                    ):(
                        <p>
                            <link
                                href="https://assets.calendly.com/assets/external/widget.css"
                                rel="stylesheet"
                            />
                            <a href="#" onClick={this.openCalendlyPopup} className="btn btnDark text-white"> Schedule Meeting</a>
                        </p>
                    )

                    }






                  </div>

                )}


              </div>
            </Col>
          </Row>

        </main>
        {/* <Footer /> */}
      </Fragment >
    );
  }
}

export default CLMCart;
