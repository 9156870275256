import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import { licenseService } from "../../../services/license";
import { settingsService } from "../../../services/settings";
import { cartService } from "../../../services/cart";
// import Footer from "../FrontEnd/Footer/Footer"
// import Header from "../FrontEnd/Header/Header"
import Heart from "../../../assets/img/heart.svg"
import corporate from "../../../assets/img/corporate.png"
import influencer from "../../../assets/img/influencer.png"
import individual from "../../../assets/img/individual.png"
import Cart from "../../../assets/img/cart.svg"
import SearchIcon from "../../../assets/img/search.svg"
import axios from "axios";
import { corporateService } from 'services/corporate';
import { toast } from 'react-toastify';
import { alert } from 'redux/reducers/AlertReducer';
import Swal from "sweetalert2";
import { nudgeCrop } from 'react-image-crop';
import { use } from 'react';


class LicenseSearch extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    const queryParams = new URLSearchParams(window.location.search);
    const keyword = queryParams.get('keyword');

    this.state = {
      value: keyword ? keyword : "",
      results: [],
      suggestions: [],
      price: [],
      topSugPrice: [],
      blockKeyword:[],
      networth: "",
      google_search_volume: "",
      instagram: "",
      twitter: "",
      redirect: false,
      salesContactAmount: 0,
      userID: user && user.data ? user.data.id : null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }




  handleSubmit(keyword, classification, networth, google_search_volume, instagram, twitter) {
    this.setState({ classification: classification, networth: networth, google_search_volume: google_search_volume, instagram: instagram, twitter: twitter });
    licenseService.checkCaretAvailability(keyword, classification, networth, google_search_volume, instagram, twitter).then((res) => {
      // console.log("######### PP=> ",res);

      this.setState({
        results: res,
        price: Array.isArray(res.price) && res.price.length > 0 ? res.price[0] : [],
        topSugPrice: Array.isArray(res.topSuggestion.price) && res.topSuggestion.price.length > 0 ? res.topSuggestion.price[0] : [],
        suggestions: res.suggestions,

      })
    })
  }



  mapDataWithPatterns = (data) => {
    const mappedData = {};

    const patterns = {
      keyword: /keyword/i,
      classification: /classification|class/i,
      net_worth: /net_worth|networth|net worth|worth/i,
      google_search_volume: /google_search_volume|google search volume|search volume/i,
      instagram: /instagram|instagram followers|insta/i,
      twitter: /twitter|twitter followers|twitter/i
    };

    Object.keys(patterns).forEach((standardKey) => {
      // Find the first matching key using the pattern
      for (let key in data) {
        if (patterns[standardKey].test(key)) {
          mappedData[standardKey] = data[key];
          break; // Stop after the first match
        }
      }
    });

    return mappedData;
  };

  handleCheckKeyword = () => {
    const { value } = this.state;
    this.setState({blockKeyword:null})
    licenseService.keywordCheckCaret(value).then((res) => {
      if (res.status === 403) {
        console.log('block keyword=> ',res.keyword);
        this.setState({blockKeyword:res.keyword});
        // toast.error(res.message);
        // // return;
        // setTimeout(() => {
        //   window.location.reload();
        // }, 700);
        // return;
      } else {
        if (res.status === 200) {
          this.handleSearchClisification(value);
        }
      }
    })

  }


  componentDidMount() {
    this.handleSalesContactAmount();
    if (this.state.value) {
      this.handleCheckKeyword();
    }
  }

  handleSalesContactAmount = () => {
    settingsService.getSalesAmount().then((res) => {
      this.setState({ salesContactAmount: res.data && res.data.value });
      // console.log('AAAAAAAAAA=>', res);
    })
  }

  handleSearchClisification = async () => {
    const { value } = this.state;
    try {
      const response = await axios.get(`https://search.carets.tv/api/generate-text?keyword=${value}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors'

        }
      );
      // this.handleSubmit(response.keyword, response.classification, );
      const mappedData = this.mapDataWithPatterns(response);
      // console.log('Mapped Data:', mappedData);
      this.handleSubmit(mappedData.keyword, mappedData.classification, mappedData.net_worth, mappedData.google_search_volume, mappedData.instagram, mappedData.twitter);
      // Pass the mapped data to store function
      this.handleStoreSearchData(mappedData);
    } catch (error) {
      // console.log('Error fetching data:', error);
    }
  };

  handleStoreSearchData = (value) => {
    // console.log("VALUE => ",value);
    corporateService.storeCaretSearchData(value).then((res) => {
      // console.log("######### res => ",res);
    })
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // this.handleSearchClisification();
      this.handleCheckKeyword();
    }
  }

  handleCorporateRequest = (planType, no, title) => {
    Swal.fire({
      title: "Authorized Agent Certification",
      html: `
      <p style="text-align: justify;">
       I certify I am an authorized agent, stakeholder, and/or owner’s representative of the respective organization associated with this ^Caret license.
       I agree to verify and provide any required documentation as deemed necessary by Carets Corporation to confirm identity and corporate association.
       I agree to speak with a ^Carets representative if necessary to facilitate this license agreement and purchase authorization. 
       I agree to the ^Caret licensing 
        <a href="https://carets.tv/terms" target="_blank" style="color: #3085d6; text-decoration: underline;">
          Terms and Conditions
        </a>.
      </p>
    `,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "I Agree",
      cancelButtonText: "Cancel",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // Set redirect state to true after confirmation
        this.handleAddToCart(no, title);
        // this.setState({ redirect: true });
        // this.props.history.push({
        //   pathname: "/CLM/CorporateRequestForm",
        //   state: { keyword: this.state.value, plan: planType },
        // });

      }
    });
  };

  // ########### Add to Cart ###################
  handleAddToCart = (no, title) => {
    const { price, value, topSugPrice, topSuggestion, suggestions, results } = this.state;
    // Validate required fields
    if (!price && !topSugPrice && !(suggestions && suggestions.length > 0)) {
      toast.error("No data available for adding to cart.");
      return;
    }

    let sourcePrice = null;

    switch (no) {
      case 1:
        sourcePrice = price;
        break;
      case 2:
        sourcePrice = topSugPrice;
        break;
      case 3:
        sourcePrice = suggestions && suggestions[0] && suggestions[0].price;
        break;
      default:
        sourcePrice = null;
        break;
    }

    if (!sourcePrice) {
      toast.error("No valid price data available.");
      return;
    }
    // console.log('sourcePrice', sourcePrice);
    let guestToken = localStorage.getItem("guest_token");
    const { id, stripe_id_one_year, one_year_license } = sourcePrice;

    const cart = {
      caret_title: title ? title : null,
      // caret_title: keyword,
      ...(guestToken && { guest_token: guestToken }),  // Only add guest_token if it exists
      subscription_pricing_id: id,
      subscription_stripe_id: stripe_id_one_year,
      subscription_amount: one_year_license,
    };
    try {
      cartService.addToCart(cart).then((res) => {
        // console.log("######### res => ",res);
        toast.success(res.message);
        if (!guestToken && res.cart && res.cart.guest_token) {
          localStorage.setItem('guest_token', res.cart.guest_token);
        }

        this.handleCartConfirmation(() => {
          window.location.replace("/CLM/CLM-cart");
        });
      }).catch((error) => {
        // Handle API error response
        toast.error('Failed to add to cart');
      });
    } catch (error) {
      console.log('Error adding to cart:', error);
    }
  }

  handleCartConfirmation = (redirectToCart) => {
    Swal.fire({
      title: 'Added to Cart!',
      text: 'Would you like to continue to the cart or shop more?',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Continue to Cart',
      cancelButtonText: 'More Shopping',
    }).then((result) => {
      if (result.isConfirmed) {
        redirectToCart();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        toast.info('Continue shopping!');
      }
    });
  };



  render() {
    const {blockKeyword, redirect, topSugPrice, sugPrice, results, suggestions, price, networth, google_search_volume, instagram, twitter, stripe_id_one_year, stripe_id_two_year, stripe_id_three_year } = this.state;

    return (
      <Fragment>
        <main className='content pl-2-5F pt-2'>
          <Row>
            <Col md={4} className='mb-20'>
              <div className="secHeading mt-0">Buy Additional ^Carets</div>
              {/* <div className='cardTitle mt-lg'>My ^Caret Licenses</div> */}
            </Col>
            
            <Col md={8} className='flexElem alignCenter gap25 flexWrap'>
            <Link to="/CLM/custom-carets" className="btn btnDark text-white" style={{ width: 'auto' }}>Create a New Caret Video</Link>
              <Link to="/CLM/search" className="btn btnDark text-white" style={{ width: 'auto' }}>Purchase Additional ^Carets</Link>
            
            </Col>

          </Row>
          <div className='flexElem alignCenter gap10p'>
            <div className='clm-search'>
              <Button style={{ backgroundColor: "transparent", border: "none" }}><img src={SearchIcon} alt='SearchIcon' width={24} /></Button>
              <input type='text'
                placeholder='Purchase your ^Caret License Today!'
                name="value"
                className='form-control'
                value={this.state.value}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              />
              <Button className="searchBtn" disabled={this.state.value === ''} onClick={this.handleCheckKeyword}>Search ^Carets</Button>
            </div>
            {/* <div>
              <Link to={"/CLM/CLM-cart"} className="btn btnDark roundedFull w-autoF text-white ml-auto">Go to cart</Link>
            </div> */}
          </div>
          { (blockKeyword && blockKeyword.title)&&
            <Row>
              <Col md={12}>
                <div className='mainResult'>
                  <div className='py-50 px-2 searchBadge'>
                    Blocked ^Carets License
                  </div>
                  <h4 className='mt-20'>^Carets License: <b>{blockKeyword.title}</b></h4>
                  <p className='mt-20' style={{color:'red'}}>This term violates the terms and conditions an is not available for purchase</p>

                </div>
              </Col>
            </Row>
          }
          {results && (results.status === "available" || results.status === "unavailable") && (
            <Row>
              <Col xs={12} md={6}>
                <div className='mainResult'>
                  <div className='py-50 px-2 searchBadge'>
                    Exact Match
                  </div>
                  <h4 className='mt-20'>{results.message}</h4>
                  {results.plan_type &&
                    <h5 className='mt-0 capitalize'>
                      {<img src={(results.plan_type == "individual" && individual ||
                        results.plan_type == "corporate" && corporate ||
                        results.plan_type == "influencer" && influencer)} alt="not found" height={20} className='mr-10' style={{ filter: 'brightness(0.2)' }} />}
                      {results.plan_type} Account</h5>
                  }
                  {/* {price.length === 0 || (price.contact_sales === 1 && price.contact_amount) ? (
                    <div></div>
                  ) : (
                    <h2 className='price mb-2'>$ {price && price.one_year_license}</h2>
                  )
                  } */}
                  {(price.length === 0 ||
                    ((price && price.contact_sales) === 1 && (price && price.contact_amount)) ||
                    (price && price.one_year_license) > Number(this.state.salesContactAmount)) ? (
                    <div></div>
                  ) : (
                    <h2 className='price mb-2'>$ {price.one_year_license}</h2>

                  )
                  }

                
                  {/* {results.plan_type &&
                    <Button color='primary' className='mt-2 px-4 btn btnDark text-white'
                      disabled={results.status === "unavailable"}
                      onClick={() => this.handleAddToCart(1, results.keyword)}
                    >
                      Add to Cart
                    </Button>
                  } */}

                  {results.plan_type && 
                    ((price.length) === 0 ||
                    ((price && price.contact_sales) === 1 && (price && price.contact_amount)) ||
                    (price && price.one_year_license) > Number(this.state.salesContactAmount)) ? (
                    <div>
                      <Button
                        color="primary"
                        className="mt-2 px-4 btn btnDark text-white"
                        disabled={results.status === "unavailable"}
                        onClick={() => this.handleCorporateRequest(results.plan_type, 1, results.keyword)}
                      >
                        Add to Cart
                      </Button>
                    </div>
                  ) : (

                    <Button color='primary' className='mt-2 px-4 btn btnDark text-white'
                      disabled={results.status === "unavailable"}
                      onClick={() => this.handleAddToCart(1, results.keyword)}
                    >
                      Add to Cart
                    </Button>
                  )}

                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className='mainResult'>
                  <div className='py-50 px-2 searchBadge'>
                    Exact Match Alternate
                  </div>
                  <div>
                    <h4 className='mt-20'>{results.topSuggestion.keyword}</h4>
                    {results.topSuggestion.plan_type &&
                      <h5 className='mt-0 capitalize'>
                        {<img src={individual} alt="not found" height={20} className='mr-10' style={{ filter: 'brightness(0.2)' }} />}
                        {results.topSuggestion.plan_type} Account</h5>
                    }
                  </div>
                  <h2 className='price mb-2'>${(topSugPrice && topSugPrice.one_year_license)}</h2>

                  {/* {results.topSuggestion.plan_type === "Corporate" ? (
                    <Link to={{
                      pathname: "/CLM/CorporateRequestForm",
                      state: {
                        keyword: results.topSuggestion.keyword, plan: results.topSuggestion.plan_type, price: topSugPrice && topSugPrice.one_year_license, two_year_license: topSugPrice && topSugPrice.two_year_license, three_year_license: topSugPrice && topSugPrice.three_year_license,
                        stripe_id_one_year: topSugPrice.stripe_id_one_year, stripe_id_two_year: topSugPrice.stripe_id_two_year, stripe_id_three_year: topSugPrice.stripe_id_three_year,
                        networth: '0', googleSearch: '0', instagram: 0, twitter: '0'
                      }
                    }}>
                      <Button color='primary' className='mt-2 px-4 btn btnDark text-white' disabled={results.status === "unavailable"}>
                        Make it yours
                      </Button>
                    </Link>
                  ) : (
                    <Link to={{
                      pathname: "/CLM/cart",
                      state: {
                        keyword: results.topSuggestion.keyword, plan: results.topSuggestion.plan_type, price: topSugPrice && topSugPrice.one_year_license, two_year_license: topSugPrice && topSugPrice.two_year_license, three_year_license: topSugPrice && topSugPrice.three_year_license,
                        stripe_id_one_year: topSugPrice && topSugPrice.stripe_id_one_year, stripe_id_two_year: topSugPrice && topSugPrice.stripe_id_two_year, stripe_id_three_year: topSugPrice && topSugPrice.stripe_id_three_year,
                        networth: '0', googleSearch: '0', instagram: 0, twitter: '0'
                      }
                    }}>
                      <Button color='primary' className='mt-2 px-4 btn btnDark text-white'>
                        Make it yours
                      </Button>
                    </Link>
                  )} */}

                  {results.topSuggestion.plan_type &&
                    <Button color='primary' onClick={() => this.handleAddToCart(2, results.topSuggestion.keyword)} className='mt-2 px-4 btn btnDark text-white'>
                      Add to Cart
                    </Button>
                  }

                </div>
              </Col>
            </Row>
          )}
          {/* ########## SUGGESTIONS ########## */}
          {results && (results.status === "available" || results.status === "unavailable") && (
            <h4>Tailored Other Suggestions</h4>
          )}
          {suggestions && suggestions.length > 0 && (
            suggestions.map((suggestion, index) => (
              <div className='suggestions' key={index}>
                <div className='suggestionsList flexElem alignCenter gap25'>
                  <h4 className='m-0'>{suggestion && suggestion.keyword}</h4>
                  <div className='ms-auto flexElem alignCenter gap25'>
                    <h4 className='text-primary m-0'>${suggestion && suggestion.price && suggestion.price.one_year_license}</h4>
                    {/* {suggestion.plan_type === "corporate" ? (
                      <Link to={{
                        pathname: "/CLM/CorporateRequestForm",
                        state: { keyword: suggestion.keyword, plan: suggestion.plan_type, price: suggestion.price }
                      }}>
                        <Button color='primary' className='mt-2 px-4' disabled={results.status === "unavailable"}>
                          Make it yours
                        </Button>
                      </Link>
                    ) : (
                      <Link to={{
                        pathname: "/CLM/cart",
                        state: {
                          keyword: suggestion.keyword, plan: suggestion.plan_type, price: suggestion.price.one_year_license,
                          two_year_license: suggestion.price.two_year_license, three_year_license: suggestion.price.three_year_license,
                          stripe_id_one_year: suggestion.price.stripe_id_one_year, stripe_id_two_year: suggestion.price.stripe_id_two_year, stripe_id_three_year: suggestion.price.stripe_id_three_year,
                          networth: '0', googleSearch: '0', instagram: 0, twitter: '0'
                        }
                      }}
                        className="cartFilter">
                        <img src={Cart} alt='Cart' width={24} />
                      </Link>

                    )} */}

                    {suggestion.plan_type &&
                      <dev
                        className="cartFilter" onClick={() => this.handleAddToCart(3, suggestion.keyword)}>
                        <img src={Cart} alt='Cart' width={24} />
                      </dev>
                    }

                  </div>
                </div>
              </div>
            ))
          )}
        </main>
      </Fragment >
    );
  }
}

export default LicenseSearch;
