
import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";

import CorporatePlanForm from "./CorporatePlanForm";

import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { splashService } from '../../../services/splash';
import { licenseService } from '../../../services/license';
import { corporateService } from '../../../services/corporate';
import { clmPlanService } from '../../../services/CLMplan';

import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Eye from "../../../assets/img/eye.svg";


var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class CorporateListingPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
    // ====================================
    this.dateValidator = new ReactValidator();
    var today = new Date();
    today.setDate(today.getDate() + 1);
    var lastmonth = new Date();
    lastmonth.setMonth(lastmonth.getMonth() - 1);
    var startDate = lastmonth.getFullYear() + '-' + (lastmonth.getMonth() + 1) + '-' + lastmonth.getDate();
    var endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // ====================================
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      campaignModal: false,
      isCompleted: false,
      campaignData: OC.CAMPAIGN,
      video_url: '',
      selectedVideo: [],
      sorted: '',
      filtered: '',
      pageSize: 10,
      userID: id,
      statusList: [],
      // formType: 'add',


      // search: { a: 1 },
      search:{
        fromDate : null,
        toDate: null,
        // status_id:false,
        // is_reported:false
      },
      subscription_plan:'',
      subscription_amount:'',
      value:"",
      caret_id:'',
      order_item_id:'',
      caret_title:'',
      company_name:'',
      user_id: '',
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getUsersCall = this.getUsersCall.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.getDurationsCall= this.getDurationsCall.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.callFunction = this.callFunction.bind(this);
    this.handleSubmitPlan = this.handleSubmitPlan.bind(this);

  };

  callFunction(formData, formType) {
    this.setState({ campaignData: false });
  }

  videoModal(url) {
    this.setState({video_url:url}, ()=>{
        console.log('video_url',this.state.video_url);
        this.setState({openVideoModal:true});
        console.log('uopenVideoModalrl', this.state.openVideoModal);
        console.log('url',this.state.video_url);
    });

  }


  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    licenseService.getLicense(id).then(res => {
      console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    licenseService.deleteLicense(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      corporateService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    licenseService.updateLicenseStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        corporateService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    splashService.blockRecord(recID).then(
      response => {
        corporateService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }





  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return corporateService.paginationData(this.state.userID, data.page, data.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }


  getUsersCall() {
    licenseService.getUsers().then(res => {
      console.log('Users', res);
      this.setState({ users: res.data });
    });
  }

  // componentDidMount() {
  //   this.getUsersCall();
  //   this.getStatusCall();

  // }


  getStatusCall = (val) => {
    licenseService.getStatus(val).then(res => {
        console.log('Status', res);
        this.setState({statusList:res.data});
        });
    }


  DateChangeHandler = (value, field) => {
    const temp = cloneDeep(this.state.search);
    temp[field] = value === null ? '' : moment(value).format("YYYY-MM-DD");

    this.setState({ search : temp  }, () => {

        if (this.dateValidator.allValid()) {
          corporateService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
          this.pagination.dataCall();
        } else {
            this.dateValidator.showMessages();
            this.forceUpdate();
        }
    })
  }
  dateElement(label, name, value, rules) {
    var selectedDate =  this.state.search[name] !== '' ? new Date(this.state.search[name]) :'';
    const { fromDate, toDate } = this.state.search;
    // console.log(value);
    return (
        <div className='form-group fullCalendar' id={name}>
              <label>{label}</label>
              <DatePicker className="form-control calender_icon"  selected={  selectedDate } name={name} onChange={ (event) => this.DateChangeHandler(event, name) } />
              {
                  ( fromDate !== '' && toDate !== '' ) &&
                  this.dateValidator.message(name, value, rules)
              }
        </div>
    );
  }

  // handleCheckPending = (checked) => {
  //   const temp = cloneDeep(this.state.search);
  //   temp['status_id'] = checked;
  //   this.setState({ search : temp  }, () => {
  //     corporateService.paginationData(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
  //     this.pagination.dataCall();
  //   });
  // }


  // ===================

componentDidMount() {
  this.getDurationsCall();
  this.getStatusCall();

  this.handleCheckPricing(this.state.userID);

}
  getDurationsCall(){
    clmPlanService.getCLMDuration().then(res => {
        // console.log('Durations',res);
        this.setState({durations:res.data});
      });
  }

  submitForm(formData, formType) {
    if (formType === 'add'){
        clmPlanService.storeCLMPlan(formData).then(res => {
          // console.log("========> ",res);
          // console.log("========> ",res.data);
          console.log("========> ",res.newPricing.id);
          this.setState({
            // subscription_plan:res.data.id,
            subscription_plan:res.newPricing.id,
            subscription_amount:res.data.amount,
            addModalForm:false,
          },() => {
            this.handleSubmitPlan();
            this.pagination.dataCall();
            setTimeout(() => {
              this.pagination.dataCall();
            } , 3000);
          });
        });
    }else if (formType === 'edit'){
        clmPlanService.editCLMPlan(formData, this.state.editRec.id).then(res => {
            this.setState({editModalForm:false});
            this.pagination.dataCall();
          });
    }
  }

  handleSubmitPlan(){
    console.log('subscription_plan', this.state.subscription_plan);
    const data = {
      subscription_plan: this.state.subscription_plan,
      subscription_amount: this.state.subscription_amount,
      id: this.state.value,
      order_item_id: this.state.order_item_id,
      caret_id: this.state.caret_id,
      caret_title: this.state.caret_title,
      user_id: this.state.user_id,
      company_name: this.state.company_name
    }
    // this.props.submitForm(this.state.license, this.state.formType);
    // if (this.state.formType === 'add') {
    corporateService.storeRequest(data).then(res => {
      console.log('After', res)
      this.pagination.dataCall();
    });
    // }

}

toggleModal = (value, original) => {
  console.log("inside functions", original);
  this.setState({
    value: original.id,
    company_name: original.company_name,
    caret_title: original.caret_title,
    caret_id: original.caret_id,
    order_item_id: original.order_item_id,
    user_id: original.user && original.user.id,
    addModalForm:true
  });
  // this.setState({
  //   addModalForm:true
  // })
}

  handleUpdateLicenseStatus = (request_id, status_id) => {
    const data = {
      request_id: request_id,
      status_id: status_id
    };
    // console.log("data", data)
    corporateService.licenseStatus(data).then((res) => {
      // console.log(res)
      setTimeout(() => {
        this.pagination.dataCall();
      }, 700)
    });
  }


handleCheckPricing = (order_id) => {
  corporateService.checkPricing(order_id).then(res => {
    console.log(res);
    if (res.data === 1) {
      this.setState({isCompleted: true})
    }else{
      this.setState({isCompleted: false})
    }
  });
}

  sendEmailToUser = () => {
    corporateService.requestEmailSendToUser(this.state.userID).then((res) => {
      console.log(res);
      this.setState({isCompleted: false});
      window.location.replace("/Admin/orders");
    })
  }

  render() {
    const { editRec, durations } = this.state;
    const { toDate, fromDate } = this.state.search;



    const columns = [
      {
        Header: "Splash Listing",
        headerClassName: 'headerPagination',
        columns: [

          {
            Header: "Caret Title",
            accessor: "caret_title",
            className: "",
            sortable: false
          },
          {
            Header: "Contact Person Name",
            accessor: "name",
            className: "",
            sortable: false
          },
          {
            Header: "Email",
            accessor: "email",
            className: "",
            sortable: false
          },
          {
            Header: "Designation",
            accessor: "designation",
            className: "",
            sortable: false
          },
          {
            Header: "Phone",
            accessor: "phone",
            className: "",
            sortable: false
          },

          {
            Header: "Company Name",
            accessor: "company_name",
            className: "",
            sortable: false
          },
          // {
          //   Header: "Company Address",
          //   accessor: "company_address",
          //   className: "",
          //   sortable: false
          // },
          {
            Header: "Company Description",
            accessor: "company_description",
            className: "",
            sortable: false
          },
          {
            Header: "Request Submitted",
            accessor: "created_at",
            Cell: ({ value }) => {
              return value ? new Date(value).toLocaleDateString() : "N/A";
          },
            className: "",
            sortable: false
          },

          // {
          //   Header: "Request Status",
          //   accessor: "status",
          //   Cell: ({ value, original }) => (
          //     <span>
          //       {value === 1 ? "Approved" : "Pending"}
          //     </span>
          //   ),
          //   className: "",
          //   sortable: false
          // },
          {
            Header: "Request Status",
            Cell: ({ row, original}) => {
              // console.log('row ==>', row._original);
              const currentStatus = row._original ? row._original.status : 0;

              return (
                <div>
                {currentStatus === 2 ? (
                  <span>Ready to Pay</span>
                ) : currentStatus === 1 ? (
                  <span>Verified</span>
                ) : (
                  <span>Pending</span>
                )}
              </div>
              );
            },
            sortable: false
          },
          // {
          //   Header: "Approval Date",
          //   accessor: "approval_date",
          //   Cell: ({ value }) => {
          //     return value ? new Date(value).toLocaleDateString() : "N/A";
          //   },
          //   className: "",
          //   sortable: false
          // },

          {
            Header: "Amount",
            accessor: "subscription_amount",
            className: "",
            sortable: false
          },
          {
            Header: "Corporate Plan",
            accessor: "id",
            Cell: ({ value, original }) => {
              // console.log('original =====> ', original);
              return (
                original.status === 0 &&(
                  <a href='javascript:void;' onClick={() => this.toggleModal(value, original)}>
                  <img src={Eye} alt='Logo' height={14} />

                </a>
              )
              );
            },
          }

        ]
      }
    ];

    return (
      <div className="content pl-2-5F">
        <Row>
          <Col md={6}>
            <div className="secHeading">Corporate ^Caret Request Status</div>
          </Col>
          <Col md={6}>
            <div className="justifyEnd flexElem flexResponsive mb-lg" style={{ "alignItems": "flex-start", "justifyContent": "space-between" }}>
              {/* <div className="">
                <OpenModalButton
                  openModal={HF.openModal.bind(this, "addModalForm")}
                  classButton={['backButton pt-sm no_radius pb-sm primary mt-none btn-block btn-info btn']}
                  buttonName="Add Licanse"
                  classIcon={['fa', 'fa-plus']}
                />
              </div> */}

              <div>
                <button
                  className="btn btn-primary"
                  disabled={!this.state.isCompleted}
                  onClick={this.sendEmailToUser}
                >
                  Send Email
                </button>
              </div>


            </div>
          </Col>

          <div className='col-md-12 mt-1'>

          {/* <Col md={2}>
              <div className='row flexElem flexResponsive align-items-center' >
                  <div className="col-md-12" >
                  {
                      this.dateElement('Date From', 'fromDate', fromDate!=='' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.search.toDate) }])
                  }
                  </div>
              </div>
          </Col>
          <Col md={2} >
              <div className='row flexElem flexResponsive align-items-center' >
                  <div className="col-md-12" >
                  {
                      this.dateElement('Date To', 'toDate', toDate!=='' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.search.fromDate) }])
                  }
                  </div>

              </div>
          </Col> */}

          {/* <Col md={2} >
            <div className='row flexElem flexResponsive align-items-center' >
                <div className="col-md-12" >
                <label>
                    <span>Pending Status</span>
                </label>
                </div>
                <div className="col-md-12 mt-sm" >
                <Switch onChange={this.handleCheckPending} checked={this.state.search.status_id} />
                </div>
            </div>
        </Col> */}
        </div>


          <Col md={12} className="mt-md">
            <Pagination
              ref={(p) => this.pagination = p}
              showPagination={false}
              columns={columns}
              pageSize={20}
              getDataCall={this.paginationCall}
              filterView={true}
              filterPlaceHolder={'Corporate'}
              defaultSorted={
                [
                  {
                    id: 'id',
                    desc: true
                  }
                ]
              }
              // downloadData={true}
              // downloadFileName={'Orders'}
              // lowerContent = {null}
              // filterPlaceHolder = {'Orders'}
              noDataText='No Record found'
              getRowProps={this.getRowProps}
            // showAllToggle={true}
            />
          </Col>
        </Row>


        { durations !== null &&
                      <Modal backdrop={'static'} show={this.state.addModalForm} onHide={HF.closeModal.bind(this, "addModalForm")} aria-labelledby="ModalHeader" >
                        <Modal.Header closeButton>
                          <Modal.Title id='ModalHeader' className="headerTitle">Add Plan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="row">
                            <div className="col-md-12">
                              <Card bsClass={["innerCard mb-none"]} content={
                                <CorporatePlanForm
                                  closeModel={HF.closeModal.bind(this, "addModalForm")}
                                  formTypePro="add"
                                  recPro={OC.CLMPLAN}
                                  submitForm= { this.submitForm }
                                  componentPro="AdListing"
                                  durations={durations}
                                />
                              } />
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    }
                    {/*Add Record Modal End*/}

              		  {/*Edit Record Modal start*/}
                    { editRec &&
                      <Modal backdrop={'static'} show={this.state.editModalForm} onHide={HF.closeModal.bind(this, "editModalForm")} aria-labelledby="ModalHeader" >
                      <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Edit Plan</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                          <Card bsClass={["innerCard mb-none"]} content={
                            <CorporatePlanForm
                              closeModel={HF.closeModal.bind(this, "editModalForm")}
                              formTypePro="edit"
                              recPro={editRec}
                              submitForm= { this.submitForm }
                              durations={durations}
                            />
                          } />
                          </div>
                        </div>
                      </Modal.Body>
                      </Modal>
                    }



      </div>
    );
  };
}
const CorporateListing= CorporateListingPage;
export default CorporateListing;


