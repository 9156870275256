
import React, { Component, Fragment, createRef } from 'react';
import { CLMUserActions } from "redux/actions/CLM-user-action";
import { Grid, } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, InputGroup, InputGroupText, FormText, Row, Col, CardHeader, Card } from "reactstrap";
// import Footer from "../../FrontEnd/Footer/Footer"
// import Header from "../../FrontEnd/Header/Header"
import SimpleReactValidator from "simple-react-validator";
import { Link, useLocation, withRouter } from "react-router-dom";
import { history } from "helper/history";
import masterCard from "../../../assets/img/sampleCard.svg"
import Swal from "sweetalert2";
import { cartService } from "../../../services/cart";
import { settingsService } from "../../../services/settings";
// import Login from "./CartLogin";
// import Signup from "./CartSignup";
import { cardService } from "../../../services/card";
import Payment from "payment";
import { forEach } from 'lodash';
import { set, toLower } from 'lodash';
import CardForm from '../CardPayment/CardForm';
var Modal = require('react-bootstrap-modal');
const moment = window.moment;




class CLMCartCheckout extends Component {
  constructor(props) {
    super(props);
  
    const user = JSON.parse(localStorage.getItem('user'));

    this.validatorForm = new SimpleReactValidator({
      autoForceUpdate: this,
      locale: "en",
    });
    this.state = {
      active: false,
      email: "",
      password: "",
      submitted: false,
      policyModal: "",
      termModal: "",
      formType: "add",
      signupData: [],
      cartList: [],
      cartListItems: [],
      salesContactAmount: 0,
      orderType: "",
      salesTaxAmount: 0,
      CardForm: false,
      flag: 0,
      useExCard: 0,
      LoginStatus: 0,
      requestAmount: false,
      userID: user.data ? user.data.id : null,
      formData: {
        terms: "",
        email: "",
        password: "",

      },
      card: {
        user_id: user && user.data ? user.data.id : null,
        card_number: "",
        card_expiry: "",
        card_cvc: "",
        // campaign_id :id,
      },
      ExCardData: [],

    }


    this.signupRef = React.createRef(); // Create a ref for the Signup component
    this.handleChange = this.handleChange.bind(this);
    this.handleCaretChange = this.handleCaretChange.bind(this);
    this.handleRec = this.handleRec.bind(this);
    this.handleRecSelect = this.handleRecSelect.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);

  }

  componentDidMount() {
    const { userID } = this.state;
    this.handleSalesTaxAmount();
    this.handleExCardData(userID);
    this.handleSalesContactAmount();
    this.handleAssociateUserId();
    setTimeout(() => {
      this.handleCartListing();

    },500);

  }
  handleSalesTaxAmount = () => {
    cartService.getSalesTax().then(res => {
      console.log(res);
      this.setState({ salesTaxAmount: res.data && res.data.value });
    })
  }



  handleCartListing() {
    let guestToken = localStorage.getItem('guest_token');

    if (guestToken) {
      // If guest_token is available, pass it in the request
      cartService.cartListing(guestToken).then(res => {
        console.log('cartListing========> ', res);
        this.setState({
          cartList: res && res.cart, // The cart details
          cartListItems: res && res.cart && res.cart.items // The items in the cart
        });

        let cartItems = res && res.cart && res.cart.items;
        let cart = res && res.cart;
        forEach(cartItems, (item) => {
          let a = 0;
          if (((item.pricing && item.pricing.contact_sales === 1) && (item.pricing && item.pricing.contact_amount)) ||
            // ((item && item.subscription_amount) > Number(this.state.salesContactAmount)) ||
            ((cart && cart.total_amount) > Number(this.state.salesContactAmount))) {
            this.setState({ requestAmount: true });
            // ++a;
            // console.log('requestAmount', a );
          }
        })

      }).catch(err => {
        console.error('Error fetching cart listing:', err);
      });
    } else {
      console.log('No guest token found in local storage');
    }
  }



  // ##########
  handleRec(e) {
    const { name, value, type, checked } = e.target;
    const recState = { ...this.state.card };
    let retV = "";

    if (name === "card_number") {
      retV = this.formatCreditCardNumber(value);
    } else if (name === "card_expiry") {
      retV = this.formatExpirationDate(value);
    } else if (name === "card_cvc") {
      retV = this.formatCVC(value);
    } else {
      retV = value;
    }
    // recState[name] = type === "checkbox" ? (checked ? 1 : 0) : retV;
    // this.setState({ card: recState });

    recState[name] = type === "checkbox" ? (checked ? 1 : 0) : retV;
    this.setState({ card: recState }, () => {
      // Call API only when checkbox is checked
      if (name === "set_default" && type === "checkbox" && checked === true) {
        this.setState({ flag: 1 });
      }
    });
  }

  handleRecSelect = (selectedOptionType, info) => {
    console.log("selectedOptionType", selectedOptionType)
    if (info['action'] === 'select-option') {
      let recState = { ...this.state.card };
      recState[info['name']] = selectedOptionType['value'];
      console.log(recState);
      this.setState({ card: recState });
    }
  }

  handleUseExistingCard = (card_id) => {
    cardService.storeExistingCard(card_id).then((res) => {
      this.setState({ useExCard: 1 });
    });
  }

  handlePayNowClick = () => {
    if (this.state.useExCard === 1) {
      this.handleLicenseCheck();
    } else if (this.state.flag === 1) {
      const mockEvent = {
        preventDefault: () => { },
      };
      this.handleSubmitForm(mockEvent);
    }
  }

  handleLicenseCheck = () => {
    let deletedItem = [];
    // Map through the cart items and return the promises
    const promises = this.state.cartListItems.map((item) => {
      let keyword = item && item.caret_title;
       cartService.checkLicenseAvailability(keyword).then((res) => {
        if (res.data === "NO") {
          deletedItem.push(keyword);
         this.deleteRecord(item && item.id);
        }
      });
    });

    // Wait for all promises to resolve
    Promise.all(promises).then(() => {
      if (deletedItem.length > 0) {
        Swal.fire({
          title: "Items Not Available",
          text: `The following items are not available: ${deletedItem.join(", ")}` + ". They have been removed from your cart Items.",
          icon: "warning",
          confirmButtonText: "OK",
        }, () => {
          this.handleCartListing();
        });
      } else {
        this.handleCartListing();
      }
    }).finally(() => {
      this.handleCheckoutClick();
    });
  };

  handleCheckoutClick = () => {
    if (this.state.cartListItems.length === 0) {
      Swal.fire({
        title: "Alert",
        text: "Your Cart is Empty",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    } else {

      let orderType = this.state.requestAmount ? 'request' : 'order';
      this.setState({ orderType: orderType });
      this.executeCheckout(orderType);
    }
  }
  executeCheckout = (orderType) => {
    this.setState({ orderType: orderType });

    cartService.checkoutCart(orderType, null).then((res) => {
      localStorage.removeItem('guest_token');
      window.location.replace('/CLM/CLM-success');
    });
  };
  deleteRecord(recID) {
    cartService.deleteItem(recID).then(res => {

    });
  }
  handleSubmitForm(e) {
    e.preventDefault();
    if (this.validatorForm.allValid()) {
      this.setState({ submitted: true }, function () {
        this.submitForm(this.state.card, this.state.formType);
      });
    } else {
      this.setState({ submitted: false });
      this.validatorForm.showMessages();
      this.forceUpdate();
    }
  }

  submitForm = (formData, formType) => {
    if (formType === 'add') {
      cardService.storeCard(formData).then(res => {
        // this.props.callFunction(this.state.card, this.state.formType);
        this.handleLicenseCheck();

      });
    } else if (formType === 'edit') {
      cardService.editCard(formData, this.state.card.id).then(res => {
        // this.props.callFunction(this.state.card, this.state.formType);
      });
    }
  }

  clearNumber = (value = "") => {
    return value.replace(/\D+/g, "");
  }

  formatCreditCardNumber = (value) => {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = this.clearNumber(value);
    let nextValue;

    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    return nextValue.trim();
  }

  formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = this.clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
  }

  formatExpirationDate = (value) => {
    const clearValue = this.clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  }
  // ########

  handleRadioChange = (e) => {
    this.setState({ active: e.target.checked })
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }


  handleCaretChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    localStorage.setItem("caret_title", value);
  }

  handleAssociateUserId = () => {
    let guestToken = localStorage.getItem('guest_token');
    cartService.associateUserId({ guest_token: guestToken }).then((res) => {
      console.log("res", res);
      this.handleSalesContactAmount();
    })
  }

  handleSalesContactAmount = () => {
    settingsService.getSalesAmount().then((res) => {
      this.setState({ salesContactAmount: res.data && res.data.value });
      // console.log('AAAAAAAAAA=>', res);
    })
  }


  handleExCardData = (userID) => {
    cardService.showExistingCardData(userID).then((res) => {
      // console.log("ExCardData", res.data.pagination.data)
      this.setState({ ExCardData: res.data.pagination.data });
    });
  }
  handleShowCardForm = () => {
    this.setState({ CardForm: true });
  }

  // %%%%%%%%%%%%%%%%
  render() {
    const { active } = this.state
    const { card, formType, CardForm, ExCardData, resultsInfo, priceInfo } = this.state;
    const { terms } = this.state.formData;
    const { loggingIn } = this.props;
    return (
      <Fragment>
        {/* <Header /> */}
        <main className='content pl-2-5F pt-2'>
          <div className='px-1'>
            <Row noGutters>
              <Col xs={12} className='px-1'>
                <h2 className='secHeading'>{this.state.requestAmount ? 'Schedule a call' : 'Check-out'}</h2>
                <div className='card borderPurple rounded5p px-2 py-2'>
                  <div className='secHeading mt-0 pb-05 mb-0'>
                    Order Summary
                  </div>
                  <div className='card-body'>
                    <div className='flexElem alignCenter spaceBetween borderB pb-05 mb-1'>
                      <label style={{marginBottom: 0, marginTop: 0}} className='mb-0'>Items:</label>
                      {/* <h4 className='m-0'>{this.state.cartList && this.state.cartList.total_items}</h4> */}
                      {this.state.cartListItems && this.state.cartListItems.length > 0 && (
                        <h4 className='m-0'>
                          {this.state.cartListItems.map(item => item.caret_title).join(", ")}
                        </h4>
                      )}

                   </div>
                    {!this.state.requestAmount ? (
                      <div>
                        <div className='flexElem alignCenter spaceBetween borderB pb-05 mb-1'>
                          <label className='mb-0'>Sub Total:</label>
                          <h4 className='m-0'>${this.state.cartList && this.state.cartList.total_amount}</h4>
                        </div>
                        <div className='flexElem alignCenter spaceBetween borderB pb-05 mb-1'>
                          <label className='mb-0'>Sales Tax:</label>
                          <h4 className='m-0'>${
                            (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                              parseFloat(this.state.salesTaxAmount || 0) / 100)}</h4>
                        </div>
                        <div className='flexElem alignCenter spaceBetween'>
                          <label className='mb-0 font-weight-bold'>Grand Total:</label>
                          <h4 className='m-0'>
                            ${
                              (
                                parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) +
                                (parseFloat(this.state.cartList && this.state.cartList.total_amount || 0) *
                                  parseFloat(this.state.salesTaxAmount || 0) / 100)
                              ).toFixed(2)
                            }
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <p className=''>Price Adjust By Admin</p>
                    )}
                    {/* <div className='flexElem alignEnd justifyEnd'>
                    <Link to="/clm-cart-checkout" className="btn btnDark w-autoF px-2-5">
                      I'm Ready to Pay
                    </Link>
                  </div> */}
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                {/* ############ */}
                {!this.state.requestAmount &&
                  (!CardForm && ExCardData.length > 0 && this.state.useExCard !== 1) &&
                  <button className='btnPurple w-autoF mb-md btn btn-secondary' onClick={() => this.handleShowCardForm()}>Add Card</button>
                }

                {!this.state.requestAmount &&
                 (!CardForm && ExCardData.length > 0) && (
                  <div className="mt-md">
                    <h4 className='mt-0'>My Cards</h4>
                    {(!CardForm && ExCardData.length > 0) && (
                      <div className="mt-md">
                        {ExCardData.map((card, index) => (
                          <div key={index} className="paymentCards">
                            <div className="flexElem spaceBetween alignStart gap10p">
                              <img src={masterCard} alt={masterCard} height={20} />
                              <Button className="btnPurple w-autoF mb-md"
                                onClick={() => this.handleUseExistingCard(card.id)}
                              >Use This Card</Button>
                            </div>
                            {/* <br></br> */}
                            {/* <h5>{card.user.name}</h5> */}
                            <div className="flexElem spaceBetween alignStart gap10p">
                              <div className="">
                                {card.card_number}
                              </div>
                              <div className="">
                                <strong>Exp: </strong> &nbsp; {card.card_expiry}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {!this.state.requestAmount && (this.state.CardForm || this.state.ExCardData.length === 0) && (
                  <div>
                    <h4 className='mt-0'>Credit card information</h4>
                    <form >
                      {formType === 'edit' &&
                        <input type="hidden" name="card_id" className="form-control" value={card.id} />
                      }
                      <div className='row'>
                        <div className="col-md-6">
                          <div className='form-group'>
                            <label htmlFor="card_number">Card Number</label>
                            <input
                              type="text"
                              name="card_number"
                              className="form-control"
                              pattern="[0-9\s]{13,19}"
                              value={card.card_number}
                              onChange={this.handleRec} />
                            {this.validatorForm.message('card_number', card.card_number, 'required|card_num')}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className='form-group'>
                            <label htmlFor="card_expiry">expiry</label>
                            <input
                              type="text"
                              name="card_expiry"
                              className="form-control"
                              placeholder="MM/YY"
                              pattern="\d\d/\d\d"
                              value={card.card_expiry}
                              onChange={this.handleRec} />
                            {this.validatorForm.message('card_expiry', card.card_expiry, 'required|card_exp')}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className='form-group'>
                            <label htmlFor="card_cvc">CVC</label>
                            <input
                              type="text"
                              name="card_cvc"
                              className="form-control"
                              pattern="\d{3,4}"
                              value={card.card_cvc}
                              onChange={this.handleRec} />
                            {this.validatorForm.message('card_cvc', card.card_cvc, 'required|max:3')}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className=''>
                            <label className="container-check"> Default Card?
                              <input type="checkbox" name="set_default" className="checkbox" value={card.set_default ? 1 : 0} onChange={this.handleRec} checked={card.set_default ? 1 : 0} />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                        </div>
                      </div>
                    </form>
                  </div>



                )}

                <button color="primary"
                  className="roundedFull btn_login"
                  disabled={
                    this.state.requestAmount
                      ? (this.state.LoginStatus === 1):
                      (!this.state.useExCard && !this.state.flag && this.state.LoginStatus === 1)
                  }
                  onClick={this.state.requestAmount? this.handleLicenseCheck: this.handlePayNowClick}>
                  {this.state.requestAmount ? 'Send Request' : 'Pay Now'}
                </button>
              </Col>
              <Col lg={5}>
                {(this.state.LoginStatus === 0 && !this.state.userID) && (
                  <div class="checkSwitch">
                    <input id="checkbox" type="checkbox" checked={active}
                      onChange={this.handleRadioChange} />
                    <label class="button" for="checkbox">
                      <div class="dot"></div>
                    </label>
                    <div class="text">
                      {
                        active ? <h4 className='m-0'>^Carets Login</h4> : <h4 className='m-0'>Already registered? please login!</h4>
                      }
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </main>
        {/* <Footer /> */}
      </Fragment>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.CLMReducer;
  return {
    loggingIn
  };
}
export default CLMCartCheckout;