import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { CLMUserActions } from "redux/actions/CLM-user-action";
import { Grid, Row, Col, Button } from "react-bootstrap";
import appBackgourndImg from "assets/img/logo_frontEnd.png";
import MessageCom from "../../layouts/Advertiser/Message";
import caretFooterLogo from "../../assets/img/logo_white.png";
import caretLogo from "../../assets/img/caretLogo.svg";
import Appstore from "../../assets/img/appleLogo.svg";
import Playstore from "../../assets/img/playstore.svg";
import logInOut from "../../assets/img/log-in-out-black.svg";
import licenserLogin from "../../assets/img/licenser-login.svg";
import advertiserLogin from "../../assets/img/advertiser-login.svg";
import videoPreview from "../../assets/img/videoPreview.png";
import videoSample from "../../assets/img/businessIMG.png";
import { Link, useLocation, withRouter } from "react-router-dom";
import { history } from "helper/history";
import MarketIcon from "../../assets/img/market-icon.svg";
import CampaignIcon from "../../assets/img/campaign-icon.svg";
import BrandAdIcon from "../../assets/img/brandAd-icon.svg";
import AdplacementIcon from "../../assets/img/adplacement-icon.svg";
import PricingIcon from "../../assets/img/pricing-icon.svg";
import LaunchcampiagnIcon from "../../assets/img/launchcampiagn-icon.svg";

import InfluencerPlan from "../../assets/img/influencer-plan.svg";
import IndivudalPlan from "../../assets/img/indivudal-plan.svg";
import CorporatePlan from "../../assets/img/corporate-plan.svg";
import StartAdvertising from "../../assets/img/start-advertising.svg";

import Video1 from "../../assets/img/video1.png";
import Video2 from "../../assets/img/video2.png";
import Video3 from "../../assets/img/video3.png";
import Video4 from "../../assets/img/video4.png";
import ArrowRight from "../../assets/img/arrow-right.svg";
import ArrowLeft from "../../assets/img/arrow-left.svg";
import Search from "../../assets/img/search.svg";
import Footer from "../FrontEnd/Footer/Footer";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        var checkLogin = localStorage.getItem("user");
        if (checkLogin) {
            // localStorage.removeItem("user");
            // this.props.dispatch(CLMUserActions.logout());
            window.location.href = "CLM/Dashboard";
        }

        const queryParams = new URLSearchParams(window.location.search);
        const keyword = queryParams.get('keyword');


        this.state = {
            email: "",
            password: "",
            submitted: false,
            role: props.role,
            caret_title: "",
            keyword: keyword ? keyword : null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCaretChange = this.handleCaretChange.bind(this);
        this.focusableDivRef = React.createRef();
    }
    componentDidMount() {
        // Check if the keyword is available and focus the element
        // if (this.state.keyword) {
            this.focusableDivRef.current.focus();
        // }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }


    // componentDidMount() {
    //     const queryParams = new URLSearchParams(window.location.search);
    //     const keyword = queryParams.get('keyword');
    //     this.setState({ keyword: keyword });
    //     console.log("Extracted Keyword:", keyword);
    // }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password, keyword } = this.state;
        const { dispatch } = this.props;
        const { role } = this.props;
        // console.log("role:", role);
        if (email && password) {
            dispatch(CLMUserActions.login(email, password, role, keyword));
        }
    }
    handleCaretChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        localStorage.setItem("caret_title", value);
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            history.push('/CLM-search');
        }
    }


    render() {
        const { email, password, submitted } = this.state;
        const { loggingIn } = this.props;
        return (
            <Fragment>
                <div className="wrapperOverride">
                    <div className="yellowBar" />
                    <div className="py-50 px-1 bg-whiteF flexElem w-full alignCenter flexWrap borderB">
                        <img
                            src={caretLogo}
                            alt={caretLogo}
                            height={36}
                            className="mx-auto mb-1 mb-md-0 ms-md-0"
                        />
                        <div className="ml-auto flexElem gap10p flexWrap-sm">
                        <Link to={"/"} className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100 appBtn alignCenter">
                        <img src={advertiserLogin} alt='advertiserLogin' width={18} style={{filter:'brightness(4)'}}/>
                        Login as Advertiser
                        </Link>
                        <Link to={"/CLM-login"} className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100 appBtn alignCenter">
                        <img src={licenserLogin} alt='licenserLogin' width={18} style={{filter:'brightness(4)'}}/>
                        Login as Licenser
                        </Link>
                        <a
                            href="https://apps.apple.com/us/app/carets/id1571154682"
                            target="_blank"
                            className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100"
                            style={{ background: "#12064b" }}
                        >
                            <img src={Appstore} alt="Appstore" width={18} />
                            <span className="flexElem flexColumn">
                                <small className="text-10">
                                    Download from
                                </small>
                                <span className="text-16">App Store</span>
                            </span>
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.carets.tv"
                            target="_blank"
                            className="flexElem gap10p text-white py-50 px-2 roundedFull hover:scale-105 transition ease transform scale-100"
                            style={{ background: "#12064b" }}
                        >
                            <img
                                src={Playstore}
                                alt="Playstore"
                                width={18}
                            />
                            <span className="flexElem flexColumn">
                                <small className="text-10">
                                    Download from 
                                </small>
                                <span className="text-16">Google Play</span>
                            </span>
                        </a>
                        <a href="#" className="loginSelection" style={{display:'none'}}>
                            <img src={logInOut} alt="logInOut" width={18} />{" "}
                            LOGIN
                            <ul className="loginMenu">
                                <li>
                                    <Link to={"/"}>
                                        <img
                                            src={advertiserLogin}
                                            alt="licenserLogin"
                                            width={18}
                                        />{" "}
                                        Login as Advertiser
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/CLM-login"}>
                                        <img
                                            src={licenserLogin}
                                            alt="advertiserLogin"
                                            width={18}
                                        />{" "}
                                        Login as Licenser
                                    </Link>
                                </li>
                            </ul>
                        </a>
                        </div>
                    </div>
                    <h2 className="text-center mb-4 max-w-md">
                        A ^Caret is Like having your own #Hashtag. Capture the power 
                        of the ^Caret to create powerful visual content. <br />
                        <br />
                        Purchase your ^Caret License Today!

                    </h2>

                    <div className="clm-search">
                        <Link to={"/CLM-search"} className="ms-1">
                            <img src={Search} alt="Search" width={24} />
                        </Link>
                        <input
                            type="text"
                            placeholder="Purchase your ^Caret License Today!"
                            name="caret_title"
                            value={this.state.caret_title}
                            className="form-control"
                            onChange={this.handleCaretChange}
                            onKeyDown={this.handleKeyDown}
                        />
                        <Link to={"/CLM-search"} className="searchBtn">
                            Search ^Carets

                        </Link>
                    </div>

                    <div class="flex overflow-hidden justify-center items-end mx-auto mb-4r text-center">
                        <video
                            playsinline=""
                            src="https://caretsffmpeg.s3.amazonaws.com/promosvideos/caretsintrodesktop.mp4"
                            autoplay
                            loop
                            preload="auto"
                            controls
                            className="max-w-md"
                            style={{ marginBottom: "-1px" }}
                        ></video>
                    </div>
                    <div className="loginSection">
                        <div className="main_login1 flexRowF">
                            <MessageCom />
                            <div className="content container">
                                <Row className="heroSection">
                                    <Col md={4}>
                                        <h1 className="text-white text-48 font-weight-bold">
                                            Create & control <br />
                                            content tied to <br />
                                            your ^Caret.
                                        </h1>
                                    </Col>
                                    <Col md={4}>
                                        <h3 className='text-36 font-weight-bold text-white textCenter'>
                                        ^Caret License Login
                                        </h3>
                                        <div className="login_form">
                                            {/* <h4 className="text-center testrrr text-white" >Increase Sales with Carets Ads</h4> */}
                                            {/* <h3 className="text-center testrrr text-white"  >CLM Login</h3> */}
                                            <form
                                                name="form"
                                                onSubmit={this.handleSubmit}

                                            >
                                                <div
                                                    className={
                                                        "form-group" +
                                                        (submitted && !email
                                                            ? " has-error"
                                                            : "")
                                                    }
                                                >
                                                    <input
                                                        type="email"
                                                        placeholder="Enter Your Email..."
                                                        className="form-control email_ctrl"
                                                        name="email"
                                                        value={email}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    {submitted && !email && (
                                                        <div className="help-block">
                                                            Email is required.
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        "form-group" +
                                                        (submitted && !password
                                                            ? " has-error"
                                                            : "")
                                                    }
                                                >
                                                    <input
                                                        type="password"
                                                        placeholder="Password"
                                                        className="form-control password_ctrl"
                                                        name="password"
                                                        value={password}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                    {submitted && !password && (
                                                        <div className="help-block">
                                                            Password is
                                                            required.
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <button className="btn btn_login">
                                                        LOGIN
                                                    </button>
                                                    {loggingIn && (
                                                        <img
                                                            alt="imageLogin"
                                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                                        />
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <a
                                                        href="/CLM-signup"
                                                        className="btn btn_login"
                                                    >
                                                        Create Account
                                                    </a>
                                                </div>
                                                <div className="flexElem alignCenter text-white">
                                                    <a
                                                        href="/send-otp"
                                                        className="text-info"
                                                    >
                                                        Forgot Password?
                                                    </a>
                                                    <span className="ml-auto">
                                                        Goto
                                                        <a
                                                            href="https://carets.tv/"
                                                            className="text-info ml-auto"
                                                        >
                                                            {" "}
                                                            ^Carets
                                                        </a>
                                                    </span>
                                                </div>
                                            </form>
                                        </div>
                                    </Col>
                                    <Col md={4} className="text-center">
                                        <h3 className="text-24 text-white mb-1">
                                            Grow your Business with your own
                                            ^Caret.
                                        </h3>
                                        <img
                                            src={videoSample}
                                            alt="videoSample"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {/* <div className='text-white loginAdWrapper'>
                                    <img src={videoPreview} alt='videoPreview' className='w-100' />
                                </div> */}
                        </div>
                    </div>
                    <div ref={this.focusableDivRef} tabIndex="0" />
                    <div className="content container py-2">
                        <Row className="mt-20">
                            <Col md={12}>
                                <h2 class="text_36 font-weight-bold text-center mb-2 mt-1">
                                    Select your ^Caret Licensing Plan
                                </h2>
                            </Col>
                            <Col md={4}>
                                <div className="px-2 py-2 mb-2 shadow text-center">
                                    <img
                                        src={IndivudalPlan}
                                        alt="IndivudalPlan"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Individual Plan
                                    </h3>
                                    <span className="text-16">
                                        Reserve your personalized
                                        <br />
                                        ^Caret.
                                    </span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="px-2 py-2 mb-2 shadow text-center">
                                    <img
                                        src={InfluencerPlan}
                                        alt="InfluencerPlan"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Influencer Plan
                                    </h3>
                                    <span className="text-16">
                                        Reserve your Influencer ^Caret to help
                                        maximize your brand.
                                    </span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="px-2 py-2 mb-2 shadow text-center">
                                    <img
                                        src={CorporatePlan}
                                        alt="CorporatePlan"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Corporate Plan
                                    </h3>
                                    <span className="text-16">
                                        Reserve your Corporate ^Carets to help
                                        maximize your brand.
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <section className="bg-lightGray">
                        <div className="content container pt-2">
                            <h2 class="text_36 font-weight-bold text-center mb-2 mt-1">
                                High Impact Videos!
                            </h2>
                            <h4 className="text-center">
                                Choose which user videos are included in your
                                ^Caret
                                <br />
                                video and add customized content.
                            </h4>
                            <div className="videoGrid">
                                <div className="appuser px-1">
                                    <span>App user Video 1</span>
                                    <img
                                        src={ArrowRight}
                                        alt="ArrowRight"
                                        width={36}
                                    />
                                </div>
                                <div>
                                    <img
                                        className="appuser-videoBanner"
                                        src={Video1}
                                        alt="Video 1"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="appuser-videoBanner"
                                        src={Video2}
                                        alt="Video 2"
                                    />
                                </div>
                                <div className="appuser-reverse px-1">
                                    <img
                                        src={ArrowLeft}
                                        alt="ArrowLeft"
                                        width={36}
                                    />{" "}
                                    <span>App user Video 2</span>{" "}
                                </div>
                                <div className="appuser px-1">
                                    <span>App user Video 3 </span>
                                    <img
                                        src={ArrowRight}
                                        alt="ArrowRight"
                                        width={36}
                                    />
                                </div>
                                <div>
                                    <img
                                        className="appuser-videoBanner"
                                        src={Video3}
                                        alt="Video 3"
                                    />
                                </div>
                                <div>
                                    <img
                                        className="appuser-videoBanner"
                                        src={Video4}
                                        alt="Video 4"
                                    />
                                </div>
                                <div className="appuser-reverse px-1">
                                    <img
                                        src={ArrowLeft}
                                        alt="ArrowLeft"
                                        width={36}
                                    />{" "}
                                    <span>
                                        User Stock image / video (Company Ad)
                                    </span>{" "}
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="content container py-2">
                        <Row className="mt-20 flexElem flexWrap">
                            <Col md={12}>
                                <h2 class="text_36 font-weight-bold text-center mb-2 mt-1">
                                    The ^Caret Advantage
                                </h2>
                            </Col>
                            <Col md={4} className="mb-2">
                                <div className="px-2 py-2 shadow h-full">
                                    <img
                                        src={MarketIcon}
                                        alt="MarketIcon"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Secure your own ^Caret
                                    </h3>
                                    <span className="text-16">
                                        Reserve your ^Caret to enhance your
                                        brand.
                                    </span>
                                </div>
                            </Col>
                            <Col md={4} className="mb-2">
                                <div className="px-2 py-2 shadow h-full">
                                    <img
                                        src={CampaignIcon}
                                        alt="MarketIcon"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Use content with your ^Caret
                                    </h3>
                                    <span className="text-16">
                                        Any user can load content with your
                                        ^Caret. You can use this content as you
                                        wish.
                                    </span>
                                </div>
                            </Col>
                            <Col md={4} className="mb-2">
                                <div className="px-2 py-2 shadow h-full">
                                    <img
                                        src={BrandAdIcon}
                                        alt="MarketIcon"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Create ads with your ^Caret
                                    </h3>
                                    <span className="text-16">
                                        Create individual ads using your ^Caret
                                        content.
                                    </span>
                                </div>
                            </Col>
                            <Col md={4} className="mb-2">
                                <div className="px-2 py-2 shadow h-full">
                                    <img
                                        src={AdplacementIcon}
                                        alt="MarketIcon"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Place ads in your ^Caret Videos
                                    </h3>
                                    <span className="text-16">
                                        Strategic placement of ads within your
                                        ^Caret videos for increase brand
                                        awareness.
                                    </span>
                                </div>
                            </Col>
                            <Col md={4} className="mb-2">
                                <div className="px-2 py-2 shadow h-full">
                                    <img
                                        src={PricingIcon}
                                        alt="MarketIcon"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Simple Pricing
                                    </h3>
                                    <span className="text-16">
                                        Pricing is tied to the brand value.
                                        Starting as low as $5/Year license.
                                    </span>
                                </div>
                            </Col>
                            <Col md={4} className="mb-2">
                                <div className="px-2 py-2 shadow h-full">
                                    <img
                                        src={LaunchcampiagnIcon}
                                        alt="MarketIcon"
                                        height={72}
                                    />
                                    <h3 className="font-boldF text-20 mb-1 mt-1">
                                        Download and Distribute
                                    </h3>
                                    <span className="text-16">
                                        Download your ^Caret videos for
                                        distribution and marketing.
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <section className="caretAdvertising">
                        <div className="container">
                            <Row className="flexElem alignCenter flexWrap">
                                <Col md={5} className="text-center">
                                    <img
                                        src={StartAdvertising}
                                        alt="StartAdvertising"
                                    />
                                </Col>
                                <Col md={7}>
                                    <h2 className="advertisingTitle">
                                        Create Branded Content with 
                                        <br />
                                        your ^Caret 
                                    </h2>
                                    <h2 className="advertisingSubTitle">
                                        {/* $0.15 PER DAY. */}
                                    </h2>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <div style={{ marginTop: "-2rem" }}>
                        <Footer />
                    </div>
                    {/* <div className='copyRights text-white flexElem alignCenter gap10p p-1'>
            <p className='font-weight-light small'>Copyright © 2023, Carets Corporation, All Rights Reserved.</p>
            <img src={caretFooterLogo} alt='footer logo' className='ml-auto' width={64} />
          </div> */}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.CLMReducer;
    return {
        loggingIn
    };
}

const Login = connect(mapStateToProps)(LoginPage);
export default Login;
